enum ActionTypeKeys {
	SIGN_IN = 'SIGN_IN',
	SIGN_IN_PENDING = 'SIGN_IN_PENDING',
	SIGN_IN_RESOLVED = 'SIGN_IN_RESOLVED',
	SIGN_IN_REJECTED = 'SIGN_IN_REJECTED',

	SIGN_OUT = 'SIGN_OUT',
	SIGN_OUT_PENDING = 'SIGN_OUT_PENDING',
	SIGN_OUT_RESOLVED = 'SIGN_OUT_RESOLVED',
	SIGN_OUT_REJECTED = 'SIGN_OUT_REJECTED',
}

export default ActionTypeKeys;
