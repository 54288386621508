import { Reducer } from 'redux';
import ActionTypes from '../actions/ActionTypes';
import initialState from '../store/initialState';

const loading: Reducer<boolean, ActionTypes> = (state = initialState.loading, action): boolean => {
	if (action.type.includes('_PENDING')) {
		return true;
	} else if (action.type.includes('_RESOLVED') || action.type.includes('_REJECTED')) {
		return false;
	}
	return state;
};

export default loading;
