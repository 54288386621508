import axios from './interceptors';
import { TOKEN_URL, REFRESH_URL, LOGOUT_URL } from './constants';
import jwtDecode from 'jwt-decode';

const getAuthenticatedUserInfo = (token: string | null) => {
	const decodedToken = token ? jwtDecode<any>(token) : null;
	if (decodedToken) {
		return {
			name: decodedToken.name,
			username: decodedToken['cognito:username'],
			account: decodedToken.account,
			email: decodedToken.email,
			groups: decodedToken['cognito:groups'],
		};
	}
	return decodedToken;
};

export const removeTokens = () => {
	window.localStorage.removeItem('idToken');
	window.localStorage.removeItem('accessToken');
};

export const authenticate = () => {
	const uriParams = new URLSearchParams(window.location.search);
	const code = uriParams.get('code');
	if (code) {
		window.history.pushState({}, 'home', window.location.pathname);
		return axios
			.post(TOKEN_URL, { code }, { withCredentials: true })
			.then((response) => {
				window.localStorage.setItem('idToken', response.data.idToken);
				window.localStorage.setItem('accessToken', response.data.accessToken);
				return getAuthenticatedUserInfo(response.data.idToken);
			})
			.catch((error) => {
				console.error(error);
				return null;
			});
	} else {
		return refreshAuth()
			.then(({ userInfo }: any) => {
				return userInfo;
			})
			.catch((error) => {
				throw error;
			});
	}
};

export const refreshAuth = () => {
	return axios
		.post(REFRESH_URL, {}, { withCredentials: true })
		.then((response) => {
			window.localStorage.setItem('idToken', response.data.idToken);
			window.localStorage.setItem('accessToken', response.data.accessToken);
			return {
				userInfo: getAuthenticatedUserInfo(response.data.idToken),
				idToken: response.data.idToken,
				accessToken: response.data.accessToken,
			};
		})
		.catch(() => {
			removeTokens();
			return { userInfo: null };
		});
};

export const signOut = () => {
	const accessToken = window.localStorage.getItem('accessToken');
	const idToken = window.localStorage.getItem('idToken');
	return axios
		.post(LOGOUT_URL, { accessToken, idToken }, { withCredentials: true })
		.then(() => {
			removeTokens();
		})
		.catch((error) => {
			throw error;
		});
};
